import React from "react";
import "./App.css";

/**
 * The parent of the entire webapp.
 * @returns
 */

function App() {
  return (
    <div className="flex min-h-screen flex-col">
      Redirecting to <a href="https://nuft.dev">nuft.dev</a>, the new home for
      our website.
    </div>
  );
}

export default App;
